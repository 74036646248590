import commonAction from 'actions/commonAction';
import AdpApi from 'api/generated/AdpApi';

export const GET_ADP_BENEFITS_ELIGIBILITY_CLASS_CODES = 'GET_ADP_BENEFITS_ELIGIBILITY_CLASS_CODES';

export const getAdpBenefitsEligibilityClassCodes = (teamId: string | undefined) =>
    commonAction(
        async () => new AdpApi().listAdpBenefitsEligibilityClassCodesForTeam(teamId),
        GET_ADP_BENEFITS_ELIGIBILITY_CLASS_CODES
    );
