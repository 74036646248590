import commonAction from 'actions/commonAction';
import MarketplaceApi from 'api/generated/MarketplaceApi';

export const GET_MARKETPLACE_PLAN_RATES_FOR_USER_ACTION = 'GET_MARKETPLACE_PLAN_RATES_FOR_USER';

export const getMarketplacePlanRatesForUser = (
    userId: string,
    excludingMembers: string[],
    year: number,
    isOffExchange: boolean
) =>
    commonAction(
        async () =>
            new MarketplaceApi().listDetailsForHousehold(
                userId,
                year,
                isOffExchange,
                excludingMembers ?? []
            ),
        GET_MARKETPLACE_PLAN_RATES_FOR_USER_ACTION,
        {
            toastErrorMessage: 'Unable to retrieve plan rates',
        }
    );
