import commonAction from 'actions/commonAction';
import AdpApi from 'api/generated/AdpApi';

export const SET_ADP_BENEFITS_ELIGIBILITY_CLASS_CODES = 'SET_ADP_BENEFITS_ELIGIBILITY_CLASS_CODES';

export const setAdpBenefitsEligibilityClassCodes = (teamId: string, classCodes: string[]) =>
    commonAction(
        async () => new AdpApi().updateAdpBenefitsEligibilityClassCodesForTeam(teamId, classCodes),
        SET_ADP_BENEFITS_ELIGIBILITY_CLASS_CODES,
        {
            toastErrorMessage: 'Failed to update ADP Benefits Eligibility Class Codes',
            toastSuccessMessage: 'Successfully updated ADP Benefits Eligibility Class Codes',
        }
    );
